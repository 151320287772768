@tailwind base;

h1 {
  @apply text-2xl font-bold;
}
h2 {
  @apply text-xl font-bold;
}
h3 {
  @apply text-lg font-bold;
}

@tailwind components;

@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.white-container {
  padding: 15px 24px 24px 24px;
  background: white;
}

.ant-picker-ok > button {
  background-color: #de584b;
}

.ant-switch {
  background-color: #cfcfcf;
}
