.ProseMirror:focus {
  outline: none;
}
ol,
ul {
  padding-left: theme('spacing.10');
}

ol {
  list-style-type: decimal;
}

ul {
  list-style-type: disc;
}
div.tiptap * td {
  background-color: rgba(236, 236, 236, 0.6);
  border: 1px white solid;
  vertical-align: top;
}
div[data-youtube-video] > iframe {
  aspect-ratio: 16 / 9;
  width: 100%;
}
