.image-overlay:hover {
  /* display: none; */
  visibility: visible;
  background: rgba(0, 0, 0, 0.5);
  opacity: 1;
}

.image-overlay {
  opacity: 0;
}
